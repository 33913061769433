import React from "react"
import { graphql } from "gatsby"
import { Link, useTranslation } from "gatsby-plugin-react-i18next"
import {
	Container,
	PrimaryBtn,
	PrimaryAlternativeBtn,
} from "components/library"

const NotFoundPage = () => {
	const { t } = useTranslation()

	return (
		<main className="flex justify-center bg-404-mobile bg-cover lg:bg-404-desktop">
			<Container className="flex flex-col gap-10 py-32 items-center">
				<div className="flex flex-col items-center text-center gap-6">
					<span className="text-5xl lg:text-7xl">{t("404-page.heading")}</span>
					<p className="text-xl lg:text-2xl mx-8 mb-4">{t("404-page.desc")}</p>
				</div>

				<div className="flex flex-col sm:flex-row gap-4">
					<Link to="/">
						<PrimaryBtn tabIndex={-1}>{t("common.cta.go-home")}</PrimaryBtn>
					</Link>
					<Link to="/contact-us/">
						<PrimaryAlternativeBtn>
							{t("common.words.contact-us")}
						</PrimaryAlternativeBtn>
					</Link>
				</div>
			</Container>
		</main>
	)
}

export default NotFoundPage

// FOR LANGUAGE TRANSLATION
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`
